.machinePageContainer {
    overflow-y: auto;
    padding: 21px 39px !important;
    width: 100%;
}

.buttonRow {
    padding: 1em 0 0.2em 0;
    display: flex;
    justify-content: space-between;
}

.leftSideButtons {
    display: flex;
    gap: 1em;
}

.rightSideButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.searchButton {
    width: auto;
}

.clearButton {
    width: auto;
}

.newMachineButton {
    width: auto;
}