.k-tabstrip-content, .k-tabstrip > .k-content {
    height: calc(100vh - 176px);
}

.AsolviTabLayout {
    overflow-y: auto;
    overflow-x: hidden;

    /* firefox scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-background-color) transparent;
}

.AsolviTabLayout .k-content::-webkit-scrollbar {
    width: 12px;
  }

.AsolviTabLayout .k-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var(--scrollbar-background-color); 
    box-shadow: inset 0 0 6px var(--scrollbar-background-color);
    border-radius: 10px;
}

.AsolviTabLayout  .k-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px var(--scrollbar-gradient-color); 
    box-shadow: inset 0 0 6px var(--scrollbar-gradient-color); 
}