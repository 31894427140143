.asolviNavBar{
    color: var(--main-color);
    background-color: var(--main-color);
    font-size: 15px;
    width: 100%;
}

.k-icon-size {
    font-size: 24px;
}

.profile-button {
    border-radius: 3.25rem !important;
    border-width: 2px !important;
    border-color: transparent !important;
    padding: .375rem .50rem !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.AsolviNavLinkRight{
    color: var(--user-menu-text-color) !important;
}

.AsolviNavLinkRight:active {
    background-color: #ececec !important;
}

.AsolviNavCustomerInfo{    
    display: block;    
    overflow: hidden;
    max-height: 45px;
    padding-top: unset;
    position: fixed;
}

.AsolviNavCustomerInfoDiv{
    color:  var(--main-header-text-color);
    display: block;    
    overflow: hidden;
    max-height: 22px;
    padding-top: unset;    
}