.AsolviDrawer {
    padding-left: 3px;
    padding-right: 0px !important;
    margin-right:0px !important;
    width: 100%;
    height: calc(100vh - 130px);
}

.fullHeightContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.flexRow {
    flex: 1 1;
}

.staticRow {
    flex: 0 0;
}

:global(.k-drawer-container) {
    width: 100%;
    height: 100%;
    padding-right: 0px !important;
    margin-right: 0px !important;    
}
:global(.k-drawer-item.k-state-selected) {
    background-color:  var(--start-left-side-text-focus-color) !important;
    -webkit-tap-hightlight-color:  var(--start-left-side-text-focus-color) !important;
}
:global(.k-drawer-content) {
    height: 100%;
    padding: 0px !important; 
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    margin-left: 0px;  
    overflow: hidden;
}

:global(.k-drawer-item) {
    user-select: none;
    margin-left: 10px;
    font-size: 20px;  
    vertical-align: text-bottom !important;
    color: var(--menu-color) !important;
}

:global(.k-drawer-item .k-icon) {
    display: inline-block;
    width:auto;
    height:auto;
    background-size:contain;
    font-size: 24px;
}
