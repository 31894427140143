.taskLogDetailsGroup{    
    margin-bottom: 15px;
    padding: 15px 15px;
    border-style: solid;
    border-width: thin;
    border-color: var(--footer-background-color);
    background-color: white;
    box-shadow: 2px 2px #ececec;
}

#taskLogModal .modal-content {
    background-color: var(--footer-background-color);
}
